.banner {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem 1.5rem;
  border-radius: 1rem;
  background-color: $gray-50;
  &--secondary {
    background-color: $white;
    border: solid 1px $gray-300;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
  &__middle-section {
    gap: 0.5rem;
  }
  &__left-section {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  &__top-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    #{$self}__header {
      font-weight: 500;
    }
  }
  &__close-button {
    padding: 0.5rem;
    border-radius: 0.75rem;
    outline: 0;
    margin-top: -0.75rem;
    margin-right: -0.75rem;
    transition: background-color 200ms;
    &:hover {
      background-color: $gray-100;
    }
    &:focus {
      background-color: $gray-200;
    }
  }
  #{$self}__text-button {
    @apply text-sm;
    font-weight: 500;
    &--left {
      padding-left: 0;
    }
    &--right {
      padding-right: 0;
    }
  }
}
