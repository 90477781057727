.button {
  $self: &;
  min-width: unset;
  font-weight: 600;
  transition:
    background-color 400ms,
    box-shadow 200ms;

  &--text-only {
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
  &--rounded {
    // padding: 0 !important;
  }

  // *********************
  // SIZES
  // *********************
  &--2xl {
    @apply text-lg;
    padding: 2rem 3rem;
  }
  &--xl {
    @apply text-md;
    padding: 1.5rem 2.5rem;
  }
  &--lg {
    @apply text-md;
    padding: 1.35rem 2.25rem;
  }
  &--md {
    @apply text-sm;
    padding: 1.35rem 2rem;
  }
  &--sm {
    @apply text-sm;
    padding: 1rem 1.75rem;
    &#{$self}--icon-only {
      padding: 1.25rem;
    }
  }
  &--xs {
    @apply text-sm;
    padding: 0.5rem 0.75rem;
    &#{$self}--icon-only {
      padding: 0.5rem;
    }
  }

  // *********************
  // PRIMARY (Blue)
  // *********************
  &--primary {
    background-color: $primary-600;
    &:hover {
      background-color: $primary-700;
    }
    &:active {
      background-color: $primary-600;
    }
    // Primary Outlined
    &#{$self}--outlined {
      color: $primary-700;
      background-color: $gray-50;
      border: solid 1px $primary-300;
      &:hover {
        color: $primary-800;
        background-color: $primary-50;
      }
      &:active {
        color: $primary-700;
        background-color: $gray-50;
      }
      &:disabled,
      &:disabled:hover {
        background-color: $white;
      }
    }
    // Primary Transparent
    &#{$self}--transparent {
      color: $primary-700;
      background-color: transparent;
      &:hover,
      &:focus {
        color: $primary-800;
        background-color: $primary-50;
      }
      &:active {
        color: $primary-700;
        background-color: transparent;
      }
    }
    // Primary Text Only
    &#{$self}--text-only {
      color: $primary-700;
      background-color: transparent;
    }
    &:disabled,
    &:disabled:hover {
      color: $gray-400;
      background-color: $gray-100;
      border: solid 1px $gray-200;
    }
  }

  // *********************
  // SECONDARY (Gray)
  // *********************
  &--secondary {
    background-color: $gray-600;
    &:hover {
      background-color: $gray-700;
    }
    &:active {
      background-color: $gray-600;
    }
    // Secondary Outlined
    &#{$self}--outlined {
      color: $gray-700;
      background-color: $white;
      border: solid 1px $gray-200;
      &:hover {
        color: $gray-800;
        background-color: $gray-100;
        border-color: $gray-300;
      }
      &:active {
        color: $gray-700;
        background-color: $white;
      }
    }
    // Secondary Transparent
    &#{$self}--transparent {
      color: $gray-700;
      background-color: $white;
      &:hover,
      &:focus {
        color: $gray-800;
        background-color: $gray-100;
      }
      &:active {
        color: $gray-700;
        background-color: $white;
      }
    }
    // Secondary Text Only
    &#{$self}--text-only {
      color: $gray-700;
      background-color: transparent;
    }
    &:disabled,
    &:disabled:hover {
      color: $gray-400;
      background-color: $white;
      border: solid 1px $gray-200;
    }
  }

  // *********************
  // DESTRUCTIVE (Red/Error)
  // *********************
  &--destructive {
    background-color: $error-600;
    &:hover {
      background-color: $error-700;
    }
    &:active {
      background-color: $error-600;
    }
    &#{$self}--outlined {
      color: $error-500;
      background-color: $white;
      border: solid 1px $error-300;
      &:hover {
        color: $error-600;
        background-color: $error-50;
      }
      &:active {
        color: $error-500;
        background-color: $white;
      }
    }
    // Destructive Transparent
    &#{$self}--transparent {
      color: $error-500;
      background-color: $white;
      &:hover,
      &:focus {
        color: $error-600;
        background-color: $error-50;
      }
      &:active {
        color: $error-500;
        background-color: $white;
      }
    }
    // Destructive Transparent
    &#{$self}--text-only {
      color: $error-500;
      background-color: transparent;
    }
  }
}
