.profile-image {
  position: relative;
  width: 5rem;
  height: 5rem;

  // Fade in prevents the no image svg from flashing before the user's avatar is loaded from userInfoContext
  animation: fadeIn 250ms;
  &__button {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    @apply focus-ring focus-ring--secondary;
    &:focus-within {
      outline: 0;
      @apply focus-ring--active;
    }
  }
  &__img-wrapper {
    width: 100%;
    height: 100%;
    background-color: $gray-300;
    border-radius: 50%;
    overflow: hidden;
  }
  &__dot {
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 1.5px $white;
  }
  &__icon {
    width: 100%;
    height: 100%;
  }
}
