.stop-progress-bar {
  $self: &;

  &__bar-container {
    height: 1rem;
    min-width: 25rem;
    background-color: $gray-200;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  &--card-variant {
    #{$self}__bar-container {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
  &--arrived {
    #{$self}__bar-container {
      background-color: $primary-600;
    }
  }
  &--departed {
    #{$self}__bar-container {
      background-color: $gray-700;
    }
  }
  &__progress-indicator {
    height: 100%;
    background-color: $primary-600;
    background-image: repeating-linear-gradient(
      -45deg,
      $primary-500,
      $primary-500 1rem,
      $primary-600 1rem,
      $primary-600 1.7rem
    );
    // arbitrarily large numbers so that the user doesn't see the animation loop
    background-size: 10000% 10000%;
    animation: inProgressAnimation linear infinite;
    // This number is a fallback, animation duration is set in the component in order
    // to ensure the speed is consistent regardless of the number of stops
    animation-duration: 500s;
  }
  &__text-content {
    font-size: 1.5rem;
    font-weight: 500;
    color: $gray-500;
    b {
      font-weight: 700;
    }
  }
}
