.radio-button {
  $self: &;

  padding: 0;
  border-radius: 50%;
  &--sm {
    height: 2rem;
    width: 2rem;
  }
  &--md {
    height: 2.5rem;
    width: 2.5rem;
  }
  &__icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px $gray-300;
    transition: box-shadow 100ms;
  }
  &--checked {
    &#{$self}--sm {
      #{$self}__icon {
        box-shadow: inset 0 0 0 5px $primary-600;
      }
    }
    &#{$self}--md {
      #{$self}__icon {
        box-shadow: inset 0 0 0 6px $primary-600;
      }
    }
  }
  &--disabled {
    #{$self}__icon {
      background-color: $gray-50;
    }
    &#{$self}--checked {
      #{$self}__icon {
        background-color: $gray-300;
        border: solid 1px $gray-300;
      }
      &#{$self}--sm {
        #{$self}__icon {
          box-shadow: inset 0 0 0 4px $gray-50;
        }
      }
      &#{$self}--md {
        #{$self}__icon {
          box-shadow: inset 0 0 0 5px $gray-50;
        }
      }
    }
  }
}
