.filter-control-option-group {
  $self: &;
  width: calc(100% + 1rem);
  max-height: 35rem;
  overflow-y: auto;
  padding-right: 1rem;
  &__radio-group {
    width: calc(100% + 1rem);
    max-height: 35rem;
    overflow-y: auto;
    padding-right: 1rem;
    > label {
      display: none;
    }
  }
  &__radio-group-root {
    margin-left: 0;
  }
  &__radio-group-items {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0.75rem 0;
    margin-left: 0;
    margin-right: 0;
    > span {
      font-weight: 500;
      margin-left: 0;
    }
  }
}
