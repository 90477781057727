.date-range-picker {
  $self: &;

  &__popup {
    .ant-picker-panel-container {
      border-radius: 0;
      box-shadow: none;
    }
    .ant-picker-header {
      border-bottom: none;
    }
    .ant-picker-cell-today .ant-picker-cell-inner {
      color: $primary-700;
      text-decoration: underline;
      &::before {
        border: none !important;
      }
    }
    // Ensures date picker instantly shows, previously did an unremovable animation
    animation-duration: 5ms;
    // Overriding ant button styles
    .ant-picker-header-prev-btn > button,
    .ant-picker-header-next-btn > button {
      @apply button button--xs button--outlined button--secondary button--icon-only;
      padding: 0.375rem !important;
      margin: 0 1.5rem;
      &:focus {
        @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
      }
    }
    // Disables second month in date range selection
    .ant-picker-panel:nth-child(2) {
      display: none;
    }
    .ant-picker-panel:nth-child(1) button {
      visibility: visible !important;
    }
    .ant-picker-cell-in-view {
      .ant-picker-cell-inner {
        border-radius: 50% !important;
        @apply text-sm;
        line-height: 3.125rem !important;
        font-weight: 600;
      }
      // Start and end date cell
      &.ant-picker-cell-range-start,
      &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: $primary-600 !important;
        }
        &::before {
          background-color: $primary-100 !important;
        }
      }
      // Dates in range, before is colored bar
      &.ant-picker-cell-in-range {
        color: $primary-700 !important;
        &::before {
          background-color: $primary-100 !important;
        }
      }
    }
  }
}
[class^="ant-picker"] {
  font-family: "galano grotesque", "DM Sans", "Helvetica", sans-serif !important;
}
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none !important;
}

.ant-calendar-picker-container {
  animation: none !important;
}
.ant-picker-range-arrow {
  display: none !important;
}
