.tabs {
  $self: &;

  &__flex-container {
    gap: 1rem;
  }

  // *********************
  // UNDERLINE TABS
  // *********************
  &--underline {
    min-height: unset;
    #{$self}__tab {
      font-weight: 600;
      padding: 0 0.5rem;
      min-width: unset;
      &--sm {
        min-height: 4rem;
        @apply text-sm;
      }
      &--md {
        min-height: 4.5rem;
        @apply text-md;
      }
      &:hover {
        color: $primary-600;
      }
    }
    #{$self}__indicator {
      height: 0.25rem;
      background-color: $primary-600;
    }
  }

  // *********************
  // FOLDER TABS
  // *********************
  &--folder {
    min-height: 5rem;
    @screen sm {
      min-height: 6rem;
    }
    #{$self}__tab {
      @apply text-sm;
      font-weight: 600;
      min-height: 5rem;
      width: 22.75rem;
      max-width: calc(50vw - 2.5rem);
      padding: 0 0.5rem;
      color: $gray-500;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 1rem 1rem 0 0;
      border-style: solid;
      border-width: 1px 1px 0;
      border-color: $gray-200;
      transition: background-color 200ms;
      @screen sm {
        @apply text-lg;
        padding: 0 0.5rem;
        max-width: calc(50vw - 2.5rem);
        min-height: 6rem;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
      &--selected {
        background-color: $white;
        color: $gray-900;
        border-color: $gray-300;
        &:hover {
          color: $primary-700;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    #{$self}__indicator {
      display: none;
    }
  }
}

#App[data-input-method="keyboard"] {
  .tabs__tab:focus {
    color: $primary-800;
  }
}
