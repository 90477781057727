.autocomplete {
  $self: &;
  border-radius: 1rem;

  &__no-options-text {
    @apply text-sm;
    color: $black;
  }
  &#{$self}--multi {
    #{$self}__input-root {
      #{$self}__tag + #{$self}__input,
      #{$self}__tag + span + #{$self}__input {
        padding-left: 0;
        padding-right: 0;
        width: 1.5rem;
        min-width: 1.5rem;
      }
    }
  }
  #{$self}__input-label {
    ::placeholder {
      @apply text-sm;
      color: $black;
      font-weight: 400;
    }
    & > label {
      @apply text-sm;
      color: $gray-500;
    }
  }
  #{$self}__input-root {
    min-width: 19rem;
    max-width: 44rem;
    min-height: 5.25rem;
    padding: 1.25rem 1.5rem;
    background-color: $white;
    border-radius: 1rem;
    border: solid 1px $gray-300;
    transition: box-shadow 200ms;
    #{$self}__input {
      @apply text-md;
      font-weight: 500;
      height: 2.5rem;
      padding: 0;
    }
    > fieldset {
      border: none;
    }
    &.Mui-disabled {
      background-color: $gray-100;
    }
  }
  #{$self}__tag {
    background-color: $white;
    border: solid 1px $gray-300;
    border-radius: 0.75rem;
    &-label {
      @apply text-md;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
  #{$self}__tag-close-button {
    padding: 0.125rem;
  }
  .MuiAutocomplete-tagSizeSmall {
    @apply text-xs;
  }
  &--focused #{$self}__input-label #{$self}__input-root {
    border-color: $primary-300;
    @apply focus-ring focus-ring--primary focus-ring--active;
  }
  &#{$self}--single {
    #{$self}__input-label {
      #{$self}__input-root {
        padding-right: 30px;
      }
    }
  }

  &__popper {
    padding-top: 0.5rem;
    min-width: 225px;
  }
  &__paper {
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    border: solid 1px $gray-200;
    box-shadow:
      0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
  &__paper {
    #{$self}__listbox {
      padding: 0 0;
      #{$self}__item {
        @apply text-md;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: wrap;
        color: $black;
        padding: 0.5rem 0.75rem;
        margin: 0.25rem 0;
        // max-height: 5.75rem;
        min-height: 5rem;
        border-radius: 1rem;
        width: 100%;
        // cursor: pointer;
        &:hover {
          background-color: $gray-50;
        }
        &.Mui-focusVisible {
          color: $primary-600;
          background-color: $primary-50;
        }
        &[aria-selected="true"] {
          background-color: $gray-50;
          &.Mui-focusVisible {
            background-color: $primary-50;
          }
        }
        &[aria-disabled="true"] {
          // pointer-events are set to none, so can't set cursor
          // cursor: not-allowed;
        }
      }
    }
  }
}
