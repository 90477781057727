.live-tracking {
  $self: &;

  animation: fadeIn 150ms;
  &.page__content {
    border-radius: 5rem 5rem 0 0;
    padding: 0;
  }
  &__top-section {
    border-radius: 0;
    transition: height 200ms;
    @screen sm {
      border-radius: 5rem 5rem 0 0;
    }
  }
  #{$self}__filters {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 1.5rem;
    #{$self}__filter {
      &--trip-status {
        min-width: 16.5rem;
      }
      input,
      input::placeholder {
        @apply text-md;
        color: $gray-700;
        font-weight: 600;
        opacity: 1;
      }
    }
  }
  &__search-wrap {
    width: 100%;
    height: 10rem;
    @screen md {
      width: calc(90% - 20rem);
      max-width: 80rem;
    }
  }
  &__search-container {
    padding: 2rem 0;
    transition: top 200ms;
    z-index: 1;
    &--top {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;
      padding: 0;
    }
    &--sticky {
      position: fixed;
      top: 0;
      left: 0;
      padding: 2rem;
      transition: top 400ms;
      box-shadow:
        0 1px 3px 0 rgba(red($gray-900), green($gray-900), blue($gray-900), 0.1),
        0 1px 2px 0
          rgba(red($gray-900), green($gray-900), blue($gray-900), 0.06);
    }
  }
  &__mega-search-container {
    width: 100%;
    max-width: 100%;
    z-index: 1;
    @screen md {
      max-width: 70rem;
    }
  }
  &__content-container {
    @screen md {
      border-radius: 1.5rem;
      border: solid 1px $gray-200;
      margin: 4rem;
    }
  }
  &__content-container-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 2rem 2rem 0;
    @screen md {
      padding: 2rem;
      border-bottom: solid 1px $gray-200;
    }
  }
  &__inner-content-container {
    &--cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
      gap: 2rem;
      padding: 2rem;
      align-items: flex-start;
      & > .trip-card {
        flex-grow: 1;
        flex-basis: 0%;
      }
    }
    &--table {
      overflow-x: scroll;
      scrollbar-width: thin;
      /* These styles enable the scroll shadows that indicate there is content off screen */
      background: /* Shadow covers */
        linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 0,
        /* Shadows */
          radial-gradient(
            farthest-side at 0 50%,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          ),
        radial-gradient(
            farthest-side at 100% 50%,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          )
          100% 0;
      background-repeat: no-repeat;
      background-color: white;
      background-size:
        40px 100%,
        40px 100%,
        14px 100%,
        14px 100%;
      /* Opera doesn't support this in the shorthand */
      background-attachment: local, local, scroll, scroll;
      border-radius: 1.5rem;
    }
  }
  &--stops {
    #{$self}__inner-content-container {
      &--cards {
        grid-template-columns: repeat(auto-fill, minmax(42rem, 1fr));
      }
    }
  }
  &__no-results {
    min-height: 40rem;
    padding: 0 1rem;
    text-align: center;
  }
  &__info-divider {
    width: 1px;
    height: 2rem;
    margin: 0 1rem;
    background-color: $gray-300;
  }
  &__filter-control {
    z-index: 3;
    transition:
      opacity 150ms,
      transform 250ms;

    &--desktop {
      position: absolute;
      right: 0;
      animation: fadeIn 150ms;
    }
    &--mobile {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 2rem;
      width: calc(100% - 4rem);
      max-width: 65rem;
      margin: 0 auto;
      animation:
        200ms ease-out 0s 1 slideInFromBottom,
        150ms ease-out 0s 1 fadeIn;
      &.filter-control--no-overlay {
        opacity: 0.01;
        transform: translateY(100%);
      }
    }
  }
}

.side-navigation {
  + .page__container {
    .live-tracking.page__content {
      border-radius: 5rem 5rem 1.5rem 1.5rem;
    }
  }
}
