.trip-progress-item {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  width: 100%;
  height: 1rem;
  background-color: $primary-100;
  &__tooltip-container {
    width: 100%;
    &:first-child {
      #{$self} {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }
    }
    &:last-child {
      #{$self} {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
  &--in-progress {
    background-image: repeating-linear-gradient(
      -45deg,
      $primary-500,
      $primary-500 1rem,
      $primary-600 1rem,
      $primary-600 1.7rem
    );
    // arbitrarily large numbers so that the user doesn't see the animation loop
    background-size: 10000% 10000%;
    animation: inProgressAnimation linear infinite;
    // This number is a fallback, animation duration is set in the component in order
    // to ensure the speed is consistent regardless of the number of stops
    animation-duration: 500s;
  }
  &--departed {
    background-color: $primary-600;
  }
  &--cancelled {
    background-color: $error-100;
    &#{$self}--departed {
      background-color: $error-600;
    }
    &#{$self}--in-progress {
      background-image: unset;
    }
  }
  &--completed {
    background-color: $gray-700;
  }
  &--skipped {
    background-color: $gray-200;
  }
  &--timed-out {
    &#{$self}--in-progress {
      background-image: unset;
    }
    &#{$self}:not(#{$self}--departed) {
      background-color: $gray-200;
    }
  }
  &--scheduled {
    background-color: $gray-200;
  }
  &__alert-icon-container {
    // This radial gradient is used to fill in the "!" of the warning icon with a white background
    background-image: radial-gradient($white 35%, transparent 35%);
  }
  &__skipped-slash {
    width: 1.75rem;
    height: 1rem;
    background-color: $white;
    transform: skew(-35deg, 0);
  }
}

@keyframes inProgressAnimation {
  100% {
    background-position: 100% 0%;
  }
}
