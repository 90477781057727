.landing-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 80vh;
  width: 100vw;
  padding: 2rem 3rem 0;
  &__buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
  }
}
