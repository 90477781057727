.table {
  thead {
    tr {
      background-color: $gray-50;
      color: $gray-600;
      th {
        @apply text-xs;
        font-weight: 500;
        text-align: start;
        padding: 1.5rem;
      }
    }
  }
  tr {
    min-height: 6rem;
    padding: 0;
    color: $gray-900;
    &:not(:last-child) {
      border-bottom: solid 1px $gray-200;
    }
    &:last-child {
      td:first-child {
        border-bottom-left-radius: 1.5rem;
      }
      td:last-child {
        border-bottom-right-radius: 1.5rem;
      }
    }
    td {
      @apply text-sm;
      font-weight: 500;
      padding: 1.5rem;
    }
  }
}
