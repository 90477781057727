.mega-search-item {
  $self: &;

  display: block;
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    background-color: $white;
    border-radius: 1rem;
    min-height: 4.85rem;
    transition: background-color 200ms;
    &:hover,
    &:focus {
      background-color: $primary-50;
      outline: 0;
      #{$self}__badge {
        position: relative;
        right: unset;
        opacity: 1;
        transition:
          background-color 200ms,
          opacity 150ms;
      }
      #{$self}__match-hint {
        display: none;
      }
    }
  }
  &__left-content {
    display: flex;
    align-items: center;
    #{$self}__icon {
      min-width: 2.5rem;
      margin-right: 1rem;
    }
    #{$self}__name {
      @apply text-md;
      color: $gray-900;
      text-align: left;
      line-height: 1.2;
      font-weight: 500;
      &--highlighted {
        font-weight: 700;
      }
    }
    #{$self}__match {
      @apply text-xs;
      color: $gray-500;
      text-align: left;
      &--highlighted {
        font-weight: 700;
      }
    }
  }
  &__right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 17rem;
    @container (max-width: 45rem) {
      display: none;
    }
    #{$self}__badge {
      position: fixed;
      right: -99999px;
      opacity: 0;
      padding-top: 0.25rem;
      white-space: nowrap;
      transition: background-color 200ms;
      &:hover {
        background-color: $primary-100;
      }
    }
    #{$self}__all-badge {
      padding-top: 0.25rem;
      white-space: nowrap;
    }
    #{$self}__match-hint {
      @apply text-sm;
      display: inline-block;
      color: $gray-800;
      font-style: italic;
    }
    &--all-results {
      min-width: unset;
    }
  }
}
