.toggle-button-group {
  $self: &;

  .toggle-button {
    @apply text-sm;
    color: $gray-900;
    border: solid 1px $gray-300;
    padding: 1.125rem 1.25rem;
    &--disabled {
      opacity: 0.2;
      background-color: $gray-100;
    }
    &--selected {
      background-color: $gray-100;
    }
    &:hover {
      background-color: $gray-100;
    }
    &--selected:hover {
      background-color: $gray-200;
    }
    &:focus-visible {
      background-color: $primary-100;
      border-color: $primary-300;
    }
    &--selected:focus-visible {
      background-color: $primary-200;
    }
    &:first-child {
      border-top-left-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }
    &:last-child {
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
  }
}
