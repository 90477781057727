$spinner-offset: 187;
$spinner-duration: 1.5s;

.loading-spinner {
  animation: spinnerRotator $spinner-duration linear infinite;
  &__path {
    stroke-dasharray: $spinner-offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: spinnerDash $spinner-duration ease-in-out infinite;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
  }
}

@keyframes spinnerRotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinnerDash {
  0% {
    stroke-dashoffset: $spinner-offset;
  }
  50% {
    stroke-dashoffset: calc($spinner-offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $spinner-offset;
    transform: rotate(720deg);
  }
}
