.trip-progress-items-condensed {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  width: 100%;
  height: 3px;
  background-color: $gray-300;
  &:first-child {
    justify-content: flex-start;
    border-radius: 3px 0 0 3px;
    background-color: $primary-600;
    #{$self}__number-indicator {
      background-color: $primary-600;
      border-color: $primary-600;
      &-text {
        color: $white;
      }
    }
  }
  &:last-child {
    justify-content: flex-end;
    border-radius: 0 3px 3px 0;
  }
  &__number-indicator {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    background-color: $white;
    border: solid 2px $gray-300;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    &-text {
      color: $gray-900;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}
