.stop-card {
  $self: &;

  // max-width: 50rem;
  min-width: 42rem;
  color: $gray-900;
  border-radius: 1.5rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  &__status-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.25rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: $white;
    border-radius: 1.5rem 1.5rem 0 0;
    border: solid 1px $gray-300;
    i {
      font-weight: 400;
    }
  }
  &__content {
    padding: 2rem;
    border-radius: 0 0 1.5rem 1.5rem;
    background-color: $gray-25;
    border: solid 1px $gray-300;
    border-top-width: 0;
  }
  &--trip-in-progress {
    #{$self}__status-header {
      background-color: $primary-100;
      color: $primary-700;
    }
    #{$self}__content {
      background-color: $primary-25;
    }
    #{$self}__inner-content-header {
      background-color: $primary-100;
      color: $primary-700;
    }
  }
  &--stop-in-progress,
  &--arrived {
    #{$self}__status-header {
      border: none;
      background-color: $primary-600;
      color: $white;
    }
    #{$self}__content {
      background-color: $primary-25;
    }
    #{$self}__inner-content-header {
      background-color: $primary-100;
      color: $primary-700;
    }
  }
  &--scheduled,
  &--skipped {
    #{$self}__status-header {
      background-color: $gray-200;
      color: $gray-700;
    }
    #{$self}__inner-content-header {
      background-color: $gray-100;
      color: $gray-500;
    }
  }
  &--cancelled {
    #{$self}__status-header {
      border: none;
      background-color: $error-600;
    }
    #{$self}__content {
      background-color: $error-25;
    }
    #{$self}__inner-content-header {
      background-color: $error-100;
      color: $error-600;
    }
  }
  &--departed {
    #{$self}__status-header {
      border: none;
      background-color: $gray-700;
      color: $white;
    }
    #{$self}__inner-content-header {
      background-color: $gray-100;
      color: $gray-700;
    }
  }
  &__inner-content {
    border: solid 1px $gray-300;
    border-top-width: 0;
    border-radius: 0 0 1.5rem 1.5rem;
  }
  &__inner-content-table {
    margin: 1.5rem 1.5rem 0;
    padding: 1rem 2rem;
    border: solid 1px $gray-300;
    border-radius: 1rem;
  }
  &__inner-content-table-row {
    @apply text-sm;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    + #{$self}__inner-content-table-row {
      margin-top: 1rem;
    }
  }
  &__inner-content-hide-show-button {
    @apply text-xs;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 1.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
    background-color: $primary-50;
    font-weight: 500;
  }
}
