.modal {
  $self: &;

  z-index: 3;
  position: fixed;
  position-anchor: --overlay-anchor;
  inset-area: center;

  max-width: calc(100% - 8rem);
  max-height: calc(100vh - 8rem);
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin: 2rem;
  background-color: $gray-50;
  border-radius: 3rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: solid 1px $gray-300;
  animation: fadeIn 200ms;
  transition: opacity 200ms;

  @screen sm {
    width: 100%;
    max-width: 61.5rem;
    padding: 4rem;
    margin: 4rem;
  }
  &--closing {
    opacity: 0;
  }

  &__inner-section {
    width: 100%;
    padding: 2rem;
    background-color: $white;
    border: solid 1px $gray-300;
    border-radius: 3rem;
    text-align: center;
    @screen sm {
      padding: 4rem;
    }
  }
}
