.snackbar {
  $self: &;

  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  background-color: $gray-950;
  border-radius: 1rem;
  &--auto-dismiss {
    animation: 500ms ease-in 4500ms 1 fadeOut;
    animation-fill-mode: forwards;
  }
  &__container {
    position: fixed;
    z-index: 9999;
    opacity: 0;
    bottom: 4rem;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 50rem;
    max-width: 100%;
    padding: 0 2rem;
    z-index: 5;
    animation:
      200ms ease-out 0s 1 slideInFromBottom,
      200ms ease-out 0s 1 fadeIn;
    animation-fill-mode: forwards, forwards;
  }
  &__body {
    @apply text-sm;
    padding: 1.25rem 2rem;
    color: $white;
    font-weight: 500;
  }
  #{$self}__button {
    display: block;
    color: $white;
    padding: 1.25rem 2rem;
    height: 100%;
    border-radius: 0 1rem 1rem 0;
    border-left: solid 1px $gray-700;
    background-color: $black;
    &:hover {
      background-color: $gray-700;
    }
  }
}
