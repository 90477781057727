.filter-control-option {
  $self: &;
  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
  }
  &__label {
    @apply text-sm;
    font-weight: 500;
  }
}
