.logo {
  $self: &;
  &__wrapper {
    &--sm {
      border-radius: 1.25rem;
      width: 6rem;
      height: 6rem;
    }
    &--md {
      border-radius: 1.5rem;
      width: 8rem;
      height: 8rem;
    }
    &--lg {
      border-radius: 2rem;
      width: 15rem;
      height: 15rem;
    }
    &--xl {
      border-radius: 3rem;
      width: 24rem;
      height: 24rem;
    }
  }
  &--symbol {
    &#{$self}--sm {
      width: 3.5rem;
      height: 3rem;
    }
    &#{$self}--md {
      width: 5.125rem;
      height: 4.5rem;
    }
    &#{$self}--lg {
      width: 10.25rem;
      height: 8.875rem;
    }
    &#{$self}--xl {
      width: 15.125rem;
      height: 13.125rem;
    }
  }
  &--full {
    &#{$self}--sm {
      width: 12rem;
      height: 3rem;
    }
    &#{$self}--md {
      width: 18rem;
      height: 4.5rem;
    }
    &#{$self}--lg {
      width: 36rem;
      height: 9rem;
    }
    // same as lg, didn't have xl design
    &#{$self}--xl {
      width: 36rem;
      height: 9rem;
    }
  }
  &__omni-text {
    &--sm {
      margin-left: 0.875rem;
      width: 8.25rem;
      height: 2.3rem;
    }
    &--md {
      margin-left: 1.3125rem;
      width: 12.33rem;
      height: 3.43rem;
    }
    &--lg {
      margin-left: 2.5rem;
      width: 24.67rem;
      height: 6.875rem;
    }
    // same as lg, didn't have xl design
    &--xl {
      margin-left: 2.5rem;
      width: 24.67rem;
      height: 6.875rem;
    }
  }
}
