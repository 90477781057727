$profileInfoWidth: 26rem;
$profileImageWidth: 5rem;
$profileImageRightMargin: 1.5rem;

.profile-info {
  $self: &;

  max-width: $profileInfoWidth;
  display: flex;
  flex-direction: row;
  &__profile-image {
    margin-right: $profileImageRightMargin;
    & + #{$self}__text #{$self}__name,
    & + #{$self}__text #{$self}__email,
    & + #{$self}__text .tooltip__container {
      max-width: $profileInfoWidth - $profileImageWidth -
        $profileImageRightMargin;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    margin-top: 0.25rem;
    #{$self}__name {
      @apply text-sm;
      max-width: $profileInfoWidth;
      color: $gray-700;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    #{$self}__email {
      @apply text-sm;
      max-width: $profileInfoWidth;
      color: $gray-600;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
