$defaultScrollbarWidth: 8px;
$timelineDrawerWidth: calc(35vw + 37.5rem);
$timelineDrawerWideWidth: 90rem;

.trip-timeline-drawer {
  $self: &;

  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  background-color: $white;
  right: 0;
  top: 0;
  transition:
    width 200ms,
    right 200ms;
  z-index: 3;
  animation: 200ms ease-out 0s 1 slideInFromRight;
  animation-fill-mode: forwards;
  &--hidden {
    right: -100%;
  }
  @screen sm {
    max-width: $timelineDrawerWidth;
    &--hidden {
      max-width: calc($timelineDrawerWidth - $defaultScrollbarWidth);
      right: calc(0 - $timelineDrawerWidth);
    }
  }
  @screen lg {
    max-width: $timelineDrawerWideWidth;
    &--hidden {
      max-width: calc($timelineDrawerWideWidth - $defaultScrollbarWidth);
      right: calc(0px - $timelineDrawerWideWidth);
    }
  }
  &__top-section {
    background-color: $primary-50;
    padding: 2rem;
    @screen sm {
      padding: 3rem;
    }
  }
  &__trip-subinfo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: solid 1px $gray-300;
    & > div {
      display: flex;
      align-items: center;
      span {
        margin-left: 0.75rem;
        font-weight: 600;
      }
    }
    @screen 2xs {
      flex-direction: row;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      gap: 0.5rem;
      & > div:not(:first-child)::before {
        content: "";
        display: inline-block;
        height: 1.5rem;
        width: 1px;
        margin: 0 1rem 1px;
        background-color: $gray-500;
      }
    }
  }
  &__trip-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0;
    &-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 100%;
      padding: 1rem 2rem;
      background-color: $white;
      border: solid 1px $gray-200;
      &:first-child {
        border-radius: 1rem 1rem 0 0;
      }
      &:not(:first-child) {
        border-top: none;
      }
      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }
      span {
        min-width: 7.5rem;
        margin-left: 0.5rem;
        font-weight: 600;
        text-align: right;
      }
    }
    @screen xs {
      flex-direction: row;
      gap: 1.5rem;
      &-cell {
        width: calc(50% - 0.75rem);
        border-radius: 1rem;
        &:first-child {
          border-radius: 1rem;
        }
        &:not(:first-child) {
          border-top: solid 1px $gray-200;
        }
        &:last-child {
          border-radius: 1rem;
        }
      }
    }
  }
  &__bottom-section {
    padding: 2rem;
    @screen sm {
      padding: 3rem;
    }
  }
  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    border-bottom: solid 1px $gray-300;
  }
  &__tabs {
    margin-bottom: -1px;
  }

  &__group-container {
    display: flex;
    flex-direction: column;
  }
  &__group-container-header {
    @apply text-md;
    background-color: $primary-100;
    border: solid 1px $gray-200;
    border-bottom: none;
    border-radius: 1.5rem 1.5rem 0 0;
    font-weight: 600;
    color: $gray-900;
    text-align: center;
  }
  &__group-container-content {
    border-radius: 0 0 1.5rem 1.5rem;
    border: solid 1px $gray-200;
    border-top: none;
    overflow: none;
    .trip-timeline-item:last-child {
      .trip-timeline-item__stop-number {
        border-bottom-left-radius: calc(1.5rem - 1px);
      }
      .trip-timeline-item__stop-content {
        border-bottom-right-radius: calc(1.5rem - 1px);
      }
    }
  }
  &__tracking-timeout-banner {
    width: 100%;
    border-radius: 1.5rem 1.5rem 1.5rem 0;
    margin-bottom: 2rem;
  }
  &__my-timezone-banner {
    width: 100%;
    padding: 1.5rem 2.5rem;
    margin-bottom: 2rem;
    #{$self}__my-timezone-banner-button {
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}
