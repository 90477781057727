.reset-password-page {
  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 36rem;
    padding: 1.5rem;
    margin: 0 auto;
    background-color: $gray-100;
    border-radius: 2rem 2rem 2rem 0;
  }
  &__password-rule {
    @apply text-sm;
    display: flex;
    align-items: center;
    line-height: 0;
    margin-right: 0.5rem;
    color: $gray-500;
    &--success {
      color: $success-600;
    }
  }
  &__snackbar-container {
    width: 60rem;
  }
}
