.navigation-menu {
  $self: &;

  &__item-container {
    margin-bottom: 1rem;
  }
  &--minimized {
    #{$self}__item-container {
      display: flex;
      justify-content: center;
    }
  }
}
