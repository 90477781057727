.support-modal {
  $self: &;
  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    margin: 0 auto;
    background-color: $gray-100;
    border-radius: 2rem 2rem 2rem 0;
  }
}
