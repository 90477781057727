.input {
  $self: &;

  padding: 1.25rem 1.5rem;
  background-color: $white;
  border-radius: 1rem;
  border: solid 1px $gray-300;
  transition: box-shadow 200ms;
  &--md {
    height: 5.5rem;
  }
  &--disabled {
    background-color: $gray-100;
  }
  &__input {
    @apply text-sm;
  }
  .icon + #{$self}__input {
    padding-left: 1rem;
  }
  &:not(#{$self}--hide-focus-ring):focus-within {
    border-color: $primary-300;
    @apply focus-ring focus-ring--primary focus-ring--active;
  }
  &#{$self}--error {
    border: solid 1px $error-300;
    &:not(#{$self}--hide-focus-ring):focus-within {
      border: solid 1px $error-300;
      @apply focus-ring focus-ring--destructive;
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    > span {
      @apply text-sm;
      color: $gray-700;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
  &__error {
    color: $error-600;
    margin-top: 0.5rem;
  }
}
