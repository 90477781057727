$navWidth: 39rem;
$navCollapsedWidth: 10rem;

html {
  // Default rem value. Do not change!
  font-size: 8px;
}
body {
  padding: 0;
  font-size: 1.75rem;
  background-color: $gray-50;
}

.page {
  &__container {
    width: 100%;
    padding-left: 0;
    transition: padding-left 200ms;
    @screen sm {
      width: calc(100% - 2rem);
    }
  }
  &__content {
    padding: 4rem;
    margin: 0;
    background-color: $white;
  }
}

.side-navigation {
  + .page__container {
    margin: 1.5rem;
    padding-top: 0;
    padding-left: $navWidth;
    .page__content {
      border: solid 1px $gray-200;
      border-radius: 1.5rem;
    }
  }
  &--minimized {
    + .page__container {
      padding-left: $navCollapsedWidth;
    }
  }
}
