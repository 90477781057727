.badge {
  $self: &;

  display: inline-flex;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.75rem;

  // *********************
  // SIZES
  // *********************
  &--xs {
    @apply text-xs;
    padding: 0 0.75rem;
    &#{$self}--rounded {
      border-radius: 2rem;
    }
  }
  &--sm {
    padding: 0.05rem 0.75rem;
    &#{$self}--rounded {
      border-radius: 2rem;
    }
  }
  &--md {
    padding: 0.1rem 1rem;
    &#{$self}--rounded {
      border-radius: 2.2rem;
    }
  }
  &--lg {
    padding: 0.5rem 1.25rem;
    &#{$self}--rounded {
      border-radius: 2.5rem;
    }
  }

  // *********************
  // COLORS
  // *********************
  &--primary {
    color: $primary-700;
    border-color: $primary-200;
    background-color: $primary-50;
  }
  &--error {
    color: $error-700;
    border-color: $error-200;
    background-color: $error-50;
  }
  &--warning {
    color: $warning-700;
    border-color: $warning-200;
    background-color: $warning-50;
  }
  &--success {
    color: $success-700;
    border-color: $success-200;
    background-color: $success-50;
  }
  &--gray {
    color: $gray-700;
    border-color: $gray-200;
    background-color: $gray-50;
  }
  &--white {
    color: $gray-700;
    border-color: $gray-200;
    background-color: $white;
  }

  // *********************
  // OUTLINE VARIANT + OUTLINED COLORS
  // *********************
  &--outline {
    border-width: 1.5px;
    background-color: $white;
    &#{$self}--primary {
      border-color: $primary-500;
    }
    &#{$self}--error {
      border-color: $error-600;
    }
    &#{$self}--warning {
      border-color: $warning-600;
    }
    &#{$self}--success {
      border-color: $success-600;
    }
    &#{$self}--gray {
      border-color: $gray-600;
    }
    &#{$self}--white {
      border-color: $gray-600;
    }
  }
}
