.signed-out-template {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: linear-gradient(
    205deg,
    #fff -12%,
    #e0eaff 38%,
    #a4bcfd 137%
  );
  &__landing-background {
    position: fixed;
    width: 120%;
    height: 100vh;
    margin-left: -10%;
    background-image: radial-gradient(
        circle at 20% 80%,
        rgba(0, 212, 255, 0.8) 0%,
        rgba(81, 81, 191, 1) 14%,
        rgba(219, 213, 215, 0.5) 17%,
        rgba(81, 81, 191, 1) 20%,
        rgba(81, 81, 191, 0.3) 60%,
        rgba(219, 213, 215, 0.5) 70%,
        rgba(81, 81, 191, 0.3) 80%,
        rgba(81, 81, 191, 0.1) 100%
      ),
      radial-gradient(
        circle at 0% 0%,
        rgba(0, 212, 255, 0.4) 0%,
        rgba(0, 212, 255, 0.4) 30%,
        rgba(81, 81, 191, 0.4) 40%,
        rgba(81, 81, 191, 0) 50%
      ),
      linear-gradient(
        to bottom,
        rgba(210, 210, 255, 1) 0%,
        rgba(0, 212, 255, 1) 5%,
        rgba(0, 212, 255, 1) 80%,
        rgba(81, 81, 191, 1) 100%
      );
    filter: blur(50px);
  }
  &__content {
    z-index: 2;
    padding: 4rem 2.5rem;
    &--landing-page {
      align-self: flex-end;
      padding: 0;
    }
  }
}
