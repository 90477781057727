.trip-timeline-table {
  $self: &;

  width: 100%;
  // min-width: 200px;
  margin-top: 2rem;
  border-radius: 1rem;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  &__time {
    font-weight: 600;
    margin-left: 0.25rem;
  }
  &__service-time td {
    color: $gray-600;
  }
  &__actual {
    min-width: 18rem;
  }
  &__scheduled {
    min-width: 12rem;
    color: $gray-700;
    font-style: italic;
    &-content {
      display: flex;
      flex-wrap: wrap;
      @screen sm {
        justify-content: space-between;
      }
    }
  }
  tr {
    td {
      @apply text-xs;
      border-right: solid 1px $gray-200;
    }
    td:nth-child(n + 2) {
      padding: 0.5rem 1rem;
      border-bottom: solid 1px $gray-200;
    }
  }
  tr:first-child {
    td:nth-child(n + 2) {
      border-top: solid 1px $gray-200;
    }
    // round right-side corners
    td:last-child {
      border-top-right-radius: 1rem;
    }
  }
  tr:last-child {
    // round right-side corners
    td:last-child {
      border-bottom-right-radius: 1rem;
    }
  }
  // first cell in row
  tr td:nth-child(1) {
    width: 1rem;
    min-width: 1rem;
  }
  // all but first cell in row
  tr td:nth-child(n + 2) {
    width: 50%;
  }
  // every other row
  tr:nth-child(2n) {
    background-color: $gray-100;
  }
}
