.table-header {
  $self: &;

  &__sort-button {
    display: flex;
    align-items: center;
    transition: color 200ms;
    &:hover {
      color: $black;
      #{$self}__sort-arrow {
        path {
          stroke: $gray-500;
        }
        &--active {
          path {
            stroke: $black;
          }
        }
      }
    }
  }
  &__sort-arrow {
    transition: transform 200ms;
    path {
      stroke: transparent;
      transition: stroke 200ms;
    }
    &--active {
      path {
        stroke: $gray-800;
      }
    }
  }
}
