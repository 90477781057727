.signed-out-container {
  $self: &;

  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 8rem);
  max-width: 80rem;
  padding: 4rem;
  margin: 4rem 0;
  background-color: $white;
  border-radius: 2rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: solid 1px $gray-300;

  @screen sm {
    margin: 0;
    background-color: $gray-50;
    border: none;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    @screen sm {
      margin-bottom: 9rem;
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
  }
  #{$self}__copy-email {
    padding-right: 0;
    color: $gray-600;
    font-weight: 500;
  }
  &__inner-section {
    width: 100%;
    max-width: 45rem;
    &--with-border {
      max-width: 57.25rem;
      margin-top: 2rem;
      padding: 6rem;
      background-color: $white;
      border: solid 1px $gray-300;
      border-radius: 3rem;
    }
  }
}
