@font-face {
  font-family: "galano grotesque";
  src: url("../assets/fonts/galanogrotesque-regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "galano grotesque";
  src: url("../assets/fonts/galanogrotesque-medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "galano grotesque";
  src: url("../assets/fonts/galanogrotesque-semibold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "galano grotesque";
  src: url("../assets/fonts/galanogrotesque-bold.otf");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "galano grotesque", "DM Sans", "Helvetica", sans-serif;
}
