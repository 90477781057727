.mega-search-group {
  $self: &;

  &__label {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    &-text {
      @apply text-xs;
      color: $gray-500;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
    }
    &-line {
      height: 1px;
      flex-grow: 1;
      background-color: $gray-100;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &:last-child {
    padding-bottom: 1rem;
  }
}
