.focus-ring {
  $self: &;

  outline: 0;
  transition: box-shadow 300ms;
  &--active {
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  &--primary {
    @include focusRing($primary-600, $primary-600, 0, 0);
    &#{$self}--active {
      animation-name: focusRingPulse--Primary;
    }
    &#{$self}--without-shadow {
      @include focusRingWithoutShadow($primary-600, 0, 0);
      &#{$self}--active {
        animation-name: focusRingPulseWithoutShadow--Primary;
      }
    }
  }
  &--secondary {
    @include focusRing($grayLightMode-800, $grayLightMode-400, 0, 0);
    &#{$self}--active {
      animation-name: focusRingPulse--Secondary;
    }
    &#{$self}--without-shadow {
      @include focusRingWithoutShadow($grayLightMode-400, 0, 0);
      &#{$self}--active {
        animation-name: focusRingPulseWithoutShadow--Secondary;
      }
    }
  }
  &--destructive {
    @include focusRing($grayLightMode-800, $error-500, 0, 0);
    &#{$self}--active {
      animation-name: focusRingPulse--Destructive;
    }
    &#{$self}--without-shadow {
      @include focusRingWithoutShadow($error-500, 0, 0);
      &#{$self}--active {
        animation-name: focusRingPulseWithoutShadow--Destructive;
      }
    }
  }
}
#App[data-input-method="keyboard"] {
  .focus-ring {
    $self: &;

    &--primary:focus-visible,
    &--primary:focus-within {
      @include focusRing($primary-600, $primary-600, 4px, 0.24);
      &#{$self}--without-shadow {
        @include focusRingWithoutShadow($primary-600, 4px, 0.24);
      }
    }
    &--secondary:focus-visible,
    &--secondary:focus-within {
      @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
      &#{$self}--without-shadow {
        @include focusRingWithoutShadow($grayLightMode-400, 4px, 0.14);
      }
    }
    &--destructive:focus-visible,
    &--destructive:focus-within {
      @include focusRing($grayLightMode-800, $error-500, 4px, 0.14);
      &#{$self}--without-shadow {
        @include focusRingWithoutShadow($error-500, 4px, 0.14);
      }
    }
  }
}

@keyframes focusRingPulse--Primary {
  0% {
    @include focusRing($primary-600, $primary-600, 0, 0.24);
  }
  20% {
    @include focusRing($primary-600, $primary-600, 4px, 0.24);
  }
  35% {
    @include focusRing($primary-600, $primary-600, 4px, 0.24);
  }
  100% {
    @include focusRing($primary-600, $primary-600, 4px, 0);
  }
}

@keyframes focusRingPulse--Secondary {
  0% {
    @include focusRing($grayLightMode-800, $grayLightMode-400, 0, 0.14);
  }
  20% {
    @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
  }
  35% {
    @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
  }
  100% {
    @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0);
  }
}
@keyframes focusRingPulse--Destructive {
  0% {
    @include focusRing($grayLightMode-800, $error-500, 0, 0.14);
  }
  20% {
    @include focusRing($grayLightMode-800, $error-500, 4px, 0.14);
  }
  35% {
    @include focusRing($grayLightMode-800, $error-500, 4px, 0.14);
  }
  100% {
    @include focusRing($grayLightMode-800, $error-500, 4px, 0);
  }
}

// ***********************
//    NO SHADOW VERSION
// ***********************

@keyframes focusRingPulseWithoutShadow--Primary {
  0% {
    @include focusRingWithoutShadow($primary-600, 0, 0.24);
  }
  20% {
    @include focusRingWithoutShadow($primary-600, 4px, 0.24);
  }
  35% {
    @include focusRingWithoutShadow($primary-600, 4px, 0.24);
  }
  100% {
    @include focusRingWithoutShadow($primary-600, 4px, 0);
  }
}

@keyframes focusRingPulseWithoutShadow--Secondary {
  0% {
    @include focusRingWithoutShadow($grayLightMode-400, 0, 0.14);
  }
  20% {
    @include focusRingWithoutShadow($grayLightMode-400, 4px, 0.14);
  }
  35% {
    @include focusRingWithoutShadow($grayLightMode-400, 4px, 0.14);
  }
  100% {
    @include focusRingWithoutShadow($grayLightMode-400, 4px, 0);
  }
}
@keyframes focusRingPulseWithoutShadow--Destructive {
  0% {
    @include focusRingWithoutShadow($error-500, 0, 0.14);
  }
  20% {
    @include focusRingWithoutShadow($error-500, 4px, 0.14);
  }
  35% {
    @include focusRingWithoutShadow($error-500, 4px, 0.14);
  }
  100% {
    @include focusRingWithoutShadow($error-500, 4px, 0);
  }
}
