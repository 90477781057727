$between-section-height: 5.25rem;
$between-section-height-in-transit: 8rem;

.trip-timeline-icon {
  $self: &;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // width here not working, need to use padding for some reason
  width: 4rem;
  padding: 0 1rem;
  @screen sm {
    padding: 0 4rem;
  }
  &__line {
    width: 2px;
    height: 50px;
    &--dashed {
      background-color: transparent !important;
      background-image: repeating-linear-gradient(
        $gray-300,
        $gray-300 0.5rem,
        transparent 0.5rem,
        transparent 1rem
      );
    }
  }

  // *********************
  // Between section
  // *********************
  &--between {
    height: $between-section-height;
    #{$self}__line--top {
      min-height: calc($between-section-height / 2);
      background-color: $gray-300;
    }
    #{$self}__line--bottom {
      min-height: calc(($between-section-height / 2) + 2rem);
      background-color: $gray-300;
      z-index: 2;
    }
    #{$self}__icon {
      margin-top: -0.375rem;
    }
    &#{$self}--active:not(#{$self}--cancelled) {
      // active stop arrived
      &#{$self}--arrived {
        #{$self}__line--top {
          background-color: $gray-300;
        }
        #{$self}__line--bottom {
          height: calc(($between-section-height / 2) + 2rem);
          background-color: $primary-600;
        }
      }
      // AV in transit to active stop
      &#{$self}--in-transit {
        height: $between-section-height-in-transit;
        #{$self}__icon {
          margin-top: -1rem;
        }
        #{$self}__line--top {
          min-height: calc($between-section-height-in-transit / 2);
          background-color: $primary-600;
        }
        #{$self}__line--bottom {
          min-height: calc(($between-section-height-in-transit / 2) + 1rem);
          background: linear-gradient($primary-600, transparent);
          margin-top: -1px;
          z-index: 2;
        }
      }
    }
    &#{$self}--completed#{$self}--arrived {
      #{$self}__line--top {
        min-height: calc($between-section-height / 2);
        background-color: $gray-300;
      }
      #{$self}__line--bottom {
        min-height: calc(($between-section-height / 2) + 2rem);
        background-color: $gray-300;
      }
    }
  }

  // *********************
  // Stop section
  // *********************
  &--stop {
    background-color: $gray-50;
    height: 100%;
    &#{$self}--active#{$self}--arrived:not(#{$self}--cancelled) {
      background-color: $primary-50;
      #{$self}__icon {
        margin-top: 1.675rem;
        margin-bottom: -0.25rem;
      }
    }
    #{$self}__icon {
      margin-top: 2rem;
      z-index: 2;
      // used to line up the dashed lines so that they look continuous
      + #{$self}__line--dashed {
        margin-top: -0.375rem;
      }
    }
    #{$self}__line--bottom {
      width: 2px;
      // 2.5rem is icon height
      // 2rem is size of margin-top on icon
      // 1px so that there's no white gap on zoom
      height: calc(100% - 2.5rem - 2rem + 1px);
      background-color: $gray-300;
    }
    // AV departed and in transit to active stop
    &#{$self}--last-departed {
      &#{$self}--in-transit {
        #{$self}__line--bottom {
          background: linear-gradient(transparent, $primary-600);
        }
      }
      &#{$self}--cancelled {
        #{$self}__line--bottom {
          background: unset;
          background-color: $gray-300;
        }
      }
    }
  }
}
