$svgSize: 1.5rem;
$uncheckedBorder: 0.125rem;
$smallCheckboxSize: 2rem;
$largeCheckboxSize: 2.5rem;

.checkbox {
  $self: &;
  &--sm {
    padding: calc(($smallCheckboxSize - $svgSize) / 2);
    border-radius: 0.5rem;
  }
  &--md {
    padding: calc(($largeCheckboxSize - $svgSize) / 2);
    border-radius: 0.75rem;
  }
  &--checked {
    @apply focus-ring--primary;
    background: $primary-600;
  }
  &:not(#{$self}--checked) {
    &#{$self}--sm {
      padding: calc(($smallCheckboxSize - $svgSize) / 2) - $uncheckedBorder;
    }
    &#{$self}--md {
      padding: calc(($largeCheckboxSize - $svgSize) / 2) - $uncheckedBorder;
    }
    @apply focus-ring--secondary;
    border: solid $uncheckedBorder $gray-300;
  }
}
