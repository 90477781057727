$buttonSize: 5rem;

.pagination {
  $self: &;

  display: flex;
  gap: 0.25rem;
  #{$self}__button {
    min-width: $buttonSize;
    height: $buttonSize;
    &--selected {
      color: $primary-600;
      background-color: $primary-50;
      &:hover,
      &:focus {
        color: $primary-600;
        background-color: $primary-100;
      }
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &__ellipsis {
    @apply text-lg;
    text-align: center;
    line-height: $buttonSize;
    @screen sm {
      width: $buttonSize;
    }
  }
}
