.settings-page {
  $self: &;

  animation: fadeIn 150ms;
  &__form {
    display: flex;
    flex-direction: column;
    // gap: 2rem;
    #{$self}__form-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      max-width: 90rem;
      padding: 3rem 0;
      border-top: none;
      @screen sm {
        flex-direction: row;
        border-top: solid 1px $gray-100;
      }
      &:not(:first-child) {
        border-top: solid 1px $gray-100;
      }
      &--no-line {
        padding-top: 1rem;
        border-top: none !important;
      }
    }
    #{$self}__form-select-input-root {
      max-width: 100%;
    }
    #{$self}__form-label,
    #{$self}__form-label > span {
      @apply text-sm;
      font-weight: 600;
      color: $gray-950;
    }
    #{$self}__form-item,
    #{$self}__password-input {
      width: 100%;
    }
    #{$self}__form-item-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      width: 100%;
      max-width: 100%;
      @screen sm {
        flex-direction: row;
        gap: 3rem;
        max-width: 50rem;
        &--two-children {
          > div {
            width: 50%;
          }
        }
      }
      @screen md {
        max-width: 60rem;
      }
    }
  }
  #{$self}__form-button-container {
    display: flex;
    max-width: 90rem;
    @screen sm {
      justify-content: end;
    }
    #{$self}__form-button {
      width: 100%;
      @screen sm {
        width: 15rem;
      }
    }
  }
  &__organization-image {
    width: 3rem;
    height: 3rem;
    border: solid 1px $gray-100;
    border-radius: 50%;
  }
  &__timezone-filter {
    min-width: 50rem;
  }
}
