.icon {
  &[class$="--up"] {
    transform: rotate(0deg);
  }
  &[class$="--right"] {
    transform: rotate(90deg);
  }
  &[class$="--down"] {
    transform: rotate(180deg);
  }
  &[class$="--left"] {
    transform: rotate(-90deg);
  }
}
