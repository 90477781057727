@mixin focusRing($color1, $color2, $width, $opacity) {
  box-shadow:
    0 1px 2px 0 rgba(red($color1), green($color1), blue($color1), 0.05),
    0 0 0 $width rgba(red($color2), green($color2), blue($color2), $opacity);
}

@mixin focusRingWithoutShadow($color, $width, $opacity) {
  box-shadow: 0 0 0 $width
    rgba(red($color), green($color), blue($color), $opacity);
}
