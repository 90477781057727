.overlay {
  $self: &;
  &__button {
    position: fixed;
    // Used to center overlay content without margin/positioning hacks.
    anchor-name: --overlay-anchor;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient($gray-400, $gray-700);
    mix-blend-mode: multiply;
    transition: opacity 325ms;
    pointer-events: none;
    opacity: 0.001;
    z-index: 3;
  }
  &--active {
    #{$self}__button {
      pointer-events: all;
      opacity: 1;
      cursor: default;
    }
  }
}
