$switchWidthSm: 4.5rem;
$switchWidthMd: 5.5rem;
$switchHeightSm: 2.5rem;
$switchHeightMd: 3rem;

.switch {
  $self: &;
  padding: 0;
  overflow: visible;
  &#{$self}--sm {
    width: $switchWidthSm;
    height: $switchHeightSm;
    #{$self}__thumb {
      width: $switchHeightSm - 0.5rem;
      height: $switchHeightSm - 0.5rem;
    }
    #{$self}__track {
      width: $switchWidthSm;
      height: $switchHeightSm;
    }
  }
  &#{$self}--md {
    width: $switchWidthMd;
    height: $switchHeightMd;
    #{$self}__thumb {
      width: $switchHeightMd - 0.5rem;
      height: $switchHeightMd - 0.5rem;
    }
    #{$self}__track {
      width: $switchWidthMd;
      height: $switchHeightMd;
    }
  }
  #{$self}__base {
    background-color: transparent;
    padding: 0.25rem;
    margin: 0;
    &:hover {
      background-color: transparent;
    }
  }
  #{$self}--checked {
    color: $white;
    &#{$self}--sm {
      transform: translateX($switchHeightSm - 0.5rem);
    }
    &#{$self}--md {
      transform: translateX($switchHeightMd - 0.5rem);
    }
    &#{$self}__base {
      &:hover {
        background-color: transparent;
      }
    }
    #{$self}__thumb {
      background-color: $white;
      border: 0;
    }
    + #{$self}__track {
      @apply focus-ring--primary;
      background-color: $primary-600;
      border: 0;
      opacity: 1;
    }
    &#{$self}--disabled {
      opacity: 0.5;
    }
  }
  &__thumb {
    box-shadow:
      0 1px 3px 0 rgba(red($gray-900), green($gray-900), blue($gray-900), 0.1),
      0 1px 2px 0 rgba(red($gray-900), green($gray-900), blue($gray-900), 0.06);
  }
  &__track {
    @apply focus-ring--secondary;
    border-radius: 1.75rem;
    background-color: $gray-100;
    transition:
      background-color 200ms,
      box-shadow 200ms;
    opacity: 1;
  }
  > #{$self}--focus-visible {
    + #{$self}__track {
      @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
    }
    &#{$self}--checked {
      + #{$self}__track {
        @include focusRing($primary-600, $primary-600, 4px, 0.24);
      }
    }
  }
  #{$self}__input {
    left: 0;
    width: 200%;
  }
  #{$self}--checked {
    #{$self}__input {
      left: -100%;
    }
  }
  &--disabled {
    opacity: 0.3;
  }
}
