$headerHeight: 7.5rem;

.mobile-navigation {
  $self: &;
  width: 100vw;

  position: relative;
  #{$self}__header {
    height: $headerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem 1rem 1rem;
    background-color: $white;
    border-bottom: solid 1px $gray-100;
    z-index: 3;
  }
  &__left-content,
  &__right-content {
    display: flex;
    align-items: center;
    transition: opacity 200ms;
    &--hidden {
      opacity: 0;
    }
  }
  #{$self}__logo-button,
  #{$self}__hamburger-button {
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    transition:
      background-color 200ms,
      opacity 200ms;
    &--hidden {
      opacity: 0;
    }
    &:focus {
      outline: 0;
      background-color: $grayLightMode-100;
    }
  }
  #{$self}__overlay {
    top: $headerHeight;
    z-index: 2;
  }
  #{$self}__hamburger-button {
    padding: 1rem;
  }
  #{$self}__logo {
    width: 15.5rem;
  }
  &--open {
    z-index: 4;
  }
}
