.navigation-item {
  $self: &;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 30rem;
  padding: 1rem 1.5rem;
  background-color: $white;
  transition:
    background-color 200ms,
    box-shadow 200ms;
  &__content {
    display: flex;
    align-items: center;
    width: calc(100% - 6rem);
  }
  &__label {
    @apply text-md;
    color: $gray-700;
    font-weight: 500;
  }
  &__icon {
    display: inline-block;
    width: 3rem;
    margin-right: 1.5rem;
    opacity: 0.6;
  }
  &__button,
  &__action-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 0.75rem;
    background-color: $white;
    transition:
      background-color 200ms,
      box-shadow 200ms;
    .icon {
      width: 2.5rem;
      opacity: 0.8;
    }
    &:hover {
      background-color: $grayLightMode-50;
    }
    &--current-path,
    &--contains-current-path {
      background-color: $grayLightMode-50;
      .icon {
        opacity: 1;
      }
    }
  }
  &__action-menu {
    display: flex;
    justify-content: center;
  }
  &:hover {
    background-color: $gray-50;
    #{$self}__label {
      color: $gray-900;
    }
  }
  &--child {
    margin-bottom: 0.5rem;
  }
  &--active {
    #{$self}__icon {
      opacity: 1;
    }
    #{$self}__label {
      color: $black;
    }
  }
  &--open {
    background-color: $gray-50;
    margin-bottom: 1rem;
  }
  &--current-path,
  &--contains-current-path {
    #{$self}__label {
      color: $gray-900;
      font-weight: 600;
    }
    #{$self}__icon {
      opacity: 1;
    }
    #{$self}__label {
      color: $black;
    }
  }
}
