.mega-search {
  $self: &;

  width: 100%;
  max-height: 100rem;
  @media screen and (min-height: 30rem) and (max-width: 700px) {
    max-height: calc(100vh - 20rem);
  }
  background-color: $white;
  border-radius: 1rem;
  // used to hide match hint and badge when container is too small
  container-type: inline-size;
  overflow-x: hidden;
  transition:
    height 200ms,
    box-shadow 200ms;

  &--focused {
    position: absolute;
    height: auto;
    border-color: $primary-300;
    @include focusRing($primary-600, $primary-600, 4px, 0.24);
  }
  &__tabs {
    margin: 1rem 0 2rem;
    border-bottom: solid 1px $gray-100;
  }
  &__container {
    position: relative;
    width: 100%;
    height: 5.75rem;
    max-width: 50rem;
  }
  &__input {
    width: 100%;
    transition: height 200ms;
    &--focused {
      height: 7rem;
      border-color: transparent;
    }
    input {
      @apply text-lg;
      font-weight: 600;
      line-height: 0;
      &::placeholder {
        @apply text-md;
        font-weight: 400;
      }
    }
  }
  &__content {
    height: 100%;
    padding: 0 1rem;
    transition:
      height 200ms,
      max-height 200ms;
    hr {
      margin: 0;
      background-color: $gray-300;
    }
  }
  &__clear-button {
    position: absolute;
    right: 0.5rem;
    padding: 1rem;
    background: linear-gradient(to right, transparent, white 20%);
    svg path {
      transition: stroke 200ms;
    }
    &:hover svg path {
      stroke: $gray-700;
    }
  }
  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0.5rem;
  }
}
