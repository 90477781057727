// These variables are needed in order to use color variables in sass functions and mixins
// Ensure that if colors change, they are replaced here and in util/colors.ts

$white: #ffffff;
$black: #0c091a;

$primary-25: #f5f8ff;
$primary-50: #eef4ff;
$primary-100: #e0eaff;
$primary-200: #c7d7fe;
$primary-300: #a4bcfd;
$primary-400: #8098f9;
$primary-500: #6172f3;
$primary-600: #444ce7;
$primary-700: #3538cd;
$primary-800: #2d31a6;
$primary-900: #2d3282;
$primary-950: #1f235b;

$brand-25: #f9f8ff;
$brand-50: #f5f3ff;
$brand-100: #f4ebff;
$brand-200: #ded3f8;
$brand-300: #cdbcf4;
$brand-400: #bda6f1;
$brand-500: #9b7ae9;
$brand-600: #7a4de2;
$brand-700: #5921db;
$brand-800: #471aaf;
$brand-900: #351483;
$brand-950: #260c64;

$grayLightMode-25: #fafafa;
$grayLightMode-50: #f5f5f6;
$grayLightMode-100: #f0f1f1;
$grayLightMode-200: #ececed;
$grayLightMode-300: #cecfd2;
$grayLightMode-400: #94969c;
$grayLightMode-500: #85888e;
$grayLightMode-600: #61646c;
$grayLightMode-700: #333741;
$grayLightMode-800: #1f242f;
$grayLightMode-900: #161b26;
$grayLightMode-950: #0c111d;

$gray-25: #fcfcfd;
$gray-50: #f8fafc;
$gray-100: #eef2f6;
$gray-200: #e3e8ef;
$gray-300: #cdd5df;
$gray-400: #9aa4b2;
$gray-500: #697586;
$gray-600: #4b5565;
$gray-700: #364152;
$gray-800: #202939;
$gray-900: #121926;
$gray-950: #0d121c;

$error-25: #fffbfa;
$error-50: #fef3f2;
$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;
$error-400: #f97066;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$error-800: #912018;
$error-900: #7a271a;
$error-950: #55160c;

$warning-25: #fffcf5;
$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-200: #fedf89;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6802;
$warning-700: #b54708;
$warning-800: #93370d;
$warning-900: #7a2e0e;
$warning-950: #4e1d09;

$success-25: #f6fef9;
$success-50: #ecfdf3;
$success-100: #dcfae6;
$success-200: #abefc6;
$success-300: #75e0a7;
$success-400: #47cd89;
$success-500: #17b26a;
$success-600: #079455;
$success-700: #067647;
$success-800: #085d3a;
$success-900: #074d31;
$success-950: #053321;
