.action-menu {
  $self: &;

  &__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    #{$self}__item {
      @apply text-sm;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-weight: 500;
      color: $gray-700;
      &--focusVisible {
        color: $primary-600;
        background-color: $primary-50;
      }
    }
  }

  &__left-content {
    display: flex;
    flex-direction: row;
  }
  &__item-start-content,
  &__item-end-content {
    font-weight: 400;
    color: $gray-500;
  }
  &__menu-paper {
    border-radius: 1rem;
    border: solid 1px $gray-200;
    box-shadow:
      0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
  &__button {
    outline: 0;
    border-radius: 50%;
    @apply focus-ring focus-ring--secondary;
  }
  &--open {
    #{$self}__button {
      @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
    }
  }
}
