.trip-timeline-item {
  $self: &;

  display: flex;
  flex-direction: column;

  #{$self}__info-divider {
    width: 1px;
    height: 2rem;
    margin: 0 1rem;
    background-color: $gray-300;
  }

  &__between-section,
  &__stop-section {
    display: flex;
    flex-direction: row;
    #{$self}__stop-number {
      @apply text-sm;
      width: 3rem;
      min-width: 3rem;
      padding-top: 2.125rem;
      color: $gray-400;
      font-weight: 500;
      text-align: center;
      background-color: $gray-100;
      @screen sm {
        @apply text-md;
        width: 11rem;
        min-width: 11rem;
        padding-top: 1.75rem;
        text-align: right;
        background-color: unset;
      }
    }
  }
  &__between-section {
    background-color: $white;
  }
  &__between-content {
    @apply text-xs;
    display: flex;
    align-items: center;
    align-self: center;
    color: $gray-600;
    font-weight: 500;
  }

  &__stop-section {
    #{$self}__stop-content {
      background-color: $gray-50;
    }
    #{$self}__stop-number {
      @screen sm {
        background-color: $gray-50;
      }
    }
  }
  &__stop-content {
    @apply text-sm;
    width: 100%;
    min-width: 25rem;
    min-height: 100%;
    padding: 1.5rem 2rem 2rem 0;
    font-weight: 600;
    @screen sm {
      @apply text-md;
    }
    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    #{$self}__stop-info {
      @apply text-xs;
      display: flex;
      align-items: center;
    }
  }
  &#{$self}--departed {
    #{$self}__stop-number {
      color: $gray-600;
    }
  }
  &#{$self}--active {
    &:not(#{$self}--cancelled) {
      #{$self}__stop-content {
        color: $primary-700;
      }
      &#{$self}--in-transit {
        #{$self}__between-section {
          background-color: $primary-50;
          #{$self}__stop-number {
            background-color: $primary-50;
          }
        }
      }
      &#{$self}--arrived {
        #{$self}__stop-section {
          #{$self}__stop-number,
          #{$self}__stop-content {
            background-color: $primary-50;
          }
          #{$self}__stop-number {
            color: $primary-700;
            font-weight: 600;
          }
        }
      }
    }
  }
}
