.trip-card {
  $self: &;

  max-width: 100%;
  min-width: 40rem;
  color: $gray-900;
  border-radius: 1.5rem;
  transition: box-shadow 200ms;
  &:hover {
    @include focusRing($grayLightMode-800, $grayLightMode-400, 4px, 0.14);
  }
  &__status-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.25rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: $white;
    border-radius: 1.5rem 1.5rem 0 0;
    i {
      font-weight: 400;
    }
  }
  &__content {
    padding: 2rem;
    border-radius: 0 0 1.5rem 1.5rem;
    background-color: $gray-25;
    border: solid 1px $gray-300;
    border-top-width: 0;
  }
  &__trip-name {
    text-align: left;
  }
  &--scheduled {
    #{$self}__status-header {
      background-color: $white;
      color: $gray-900;
      border: solid 1px $gray-300;
    }
    #{$self}__content {
      background-color: $gray-50;
    }
  }
  &--in-progress {
    #{$self}__status-header {
      background-color: $primary-600;
    }
    #{$self}__content {
      background-color: $primary-25;
    }
  }
  &--cancelled {
    #{$self}__status-header {
      background-color: $error-600;
    }
    #{$self}__content {
      background-color: $error-25;
    }
  }
  &--completed {
    #{$self}__status-header {
      background-color: $gray-600;
    }
    #{$self}__content {
      background-color: $gray-50;
    }
  }
  &--timed-out {
    #{$self}__status-header {
      background-color: $gray-200;
      color: $gray-900;
      border: solid 1px $gray-300;
    }
  }
  &__trip-info {
    b {
      font-weight: 600;
      white-space: nowrap;
    }
  }
  &__inner-container {
    padding: 2rem;
    background-color: $white;
    border: solid 1px $gray-300;
    border-radius: 1rem;
    span {
      text-align: left;
    }
    b {
      font-weight: 600;
    }
  }
  &__table-left {
    min-width: 15rem;
  }
}
