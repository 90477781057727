.filter-control {
  $self: &;

  width: 100%;
  padding: 2rem;
  background-color: $white;
  border-radius: 1rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  transition: height 200ms;
  @screen sm {
    width: 42rem;
  }
  &__top-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 4rem;
    margin-bottom: 1rem;
  }
  &__middle-section {
    height: 100%;
    transition:
      height 200ms,
      max-height 200ms;
  }
  &__bottom-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 1rem;
    margin-top: 2rem;
    // bg color and box shadow are used to hide text from appearing behind buttons on transition
    background-color: $white;
    box-shadow:
      0 1.875rem 0 0 $white,
      0 -1rem 1rem 0 $white;
    z-index: 2;
    #{$self}__bottom-button {
      width: 100%;
    }
    @screen xs {
      flex-direction: row-reverse;
      gap: 2rem;
    }
  }
  #{$self}__icon-button {
    padding: 0.875rem;
  }
  &__filter-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    border-radius: 1rem;
    &-label {
      @apply text-sm;
      font-weight: 500;
    }
    &-value {
      @apply text-sm;
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 600;
      transition: transform 150ms;
      &--active {
        color: $primary-700;
      }
    }
    &:hover,
    &:focus {
      outline: none;
      #{$self}__filter-button-value {
        text-decoration: underline;
      }
    }
  }
}
