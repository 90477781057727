.sign-in-page {
  &__okta-button {
    width: 100%;
  }
  &__okta-logo-aura {
    height: 3rem;
  }
  &__okta-logo-text {
    height: 2rem;
  }
  &__or-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0;

    @apply text-sm;
    color: $gray-500;
    &:before,
    &:after {
      content: "";
      height: 1px;
      width: calc(50% - 4rem);
      background-color: $gray-300;
    }
  }
}
