$sideNavWidth: 39rem;
$collapsedSideNavWidth: 10rem;

.side-navigation {
  $self: &;

  position: fixed;
  display: flex;

  anchor-name: --side-nav-anchor;

  justify-content: center;
  width: $sideNavWidth;
  height: 100vh;
  max-height: 100vh;
  // overflow: scroll;
  padding: 4rem 1rem 1rem;
  background-color: $white;
  border-right: solid 1px $gray-200;
  left: 0;
  top: 0;
  transition:
    width 200ms,
    left 200ms;
  z-index: 3;
  #{$self}__expand-collapse-button {
    position: fixed;
    display: block;
    left: $sideNavWidth - 2rem;
    top: 0;
    height: 100vh;
    width: 2rem;
    border-right: solid 0 $primary-500;
    transition: all 200ms;
    &:hover,
    &:focus {
      outline: 0;
      width: 3rem;
      border-right-width: 3px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  &__top-section {
    display: flex;
    flex-direction: column;
    #{$self}__logo {
      width: 14rem;
    }
    #{$self}__logo-button {
      width: 16.25rem;
      border-radius: 0.75rem;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      transition: background-color 200ms;
      &:focus {
        outline: 0;
        background-color: $grayLightMode-100;
      }
    }
  }
  &--minimized {
    width: 10rem;
    justify-content: flex-start;
    #{$self}__expand-collapse-button {
      left: $collapsedSideNavWidth - 2rem;
    }
    #{$self}__content {
      width: 8rem;
    }
    #{$self}__top-section {
      align-items: center;
      #{$self}__logo {
        width: 2.75rem;
        margin-left: 0;
      }
      #{$self}__logo-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grayLightMode-50;
        width: 4.5rem;
        height: 4.5rem;
        padding: 0;
        margin-bottom: 2rem;
        transition: background-color 200ms;
        &:hover {
          background-color: $grayLightMode-100;
        }
        &:focus {
          background-color: $grayLightMode-200;
        }
      }
    }
    #{$self}__profile-menu-container {
      display: flex;
      justify-content: center;
    }
  }
  &__bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 3rem;
    #{$self}__profile-section {
      display: flex;
      justify-content: space-between;
      padding-top: 3rem;
      border-top: solid 1px $gray-100;
    }
  }
  &--hidden {
    left: -$sideNavWidth;
  }
  &--mobile {
    top: $headerHeight;
    height: calc(100vh - $headerHeight);
    max-height: calc(100vh - $headerHeight);
    max-width: calc(100vw - 7rem);
    animation: 200ms ease-out 0s 1 slideInFromLeft;
    animation-fill-mode: forwards;
  }
  #{$self}__close-button {
    position: absolute;
    position-anchor: --side-nav-anchor;
    left: calc(100% + 1rem);
    z-index: 999999;
    display: block;
    // left: 1rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 1rem;
    opacity: 0.7;
    transition:
      opacity 200ms,
      background-color 200ms,
      left 200ms;
    z-index: 3;
    &:hover,
    &:focus {
      outline: 0;
      background-color: rgba(255, 255, 255, 0.2);
    }
    animation: 200ms ease-out 0s 1 slideInFromLeft;
    animation-fill-mode: forwards;
    @screen xs {
      left: calc(100% + 3rem);
    }
  }
  &--hidden #{$self}__close-button {
    opacity: 0;
    left: -5rem;
  }
}
