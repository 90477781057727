.trip-progress-bar {
  display: flex;
  flex-direction: column;
  &__stops {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 1rem;
    min-width: 20rem;
    height: 2.5rem;
    container-type: inline-size;
  }
  &__text-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: $gray-500;
    b {
      color: $gray-900;
      font-weight: 600;
    }
  }
}
